import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Stack, Drawer, useTheme } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// config
import { NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
//
import navConfig from './config-navigation';
import NavDocs from './NavDocs';
import NavToggleButton from './NavToggleButton';
import { NavSectionVertical } from 'src/components/nav-section/vertical/NavSectionVertical';
import ChatHistory from './ChatHistory';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { pathname } = useLocation();

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 1.5,
          pb: 2,
          flexShrink: 0,
          marginRight: 7.5,
        }}
      >
        <Logo />
      </Stack>
      <NavSectionVertical data={navConfig} />
      {pathname === '/console/home' && <ChatHistory />}
      <Box sx={{ flexGrow: 1 }} />
      <NavDocs />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      <NavToggleButton />

      <Drawer
        open={openNav}
        onClose={onCloseNav}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            width: NAV.W_DASHBOARD,
            bgcolor: isDarkMode ? theme.palette.background.default : theme.palette.background.paper,
          },
        }}
      >
        {renderContent}
      </Drawer>
    </Box>
  );
}
