import { User } from '../auth/types';
import { DetailedThreats } from './threatDetails';
import { ThreatIntelligence } from './threatIntelligence';

export interface AlertComment {
  id: string;
  alert_id: string;
  parent_id: string;
  user_id: number;
  user_name: string;
  user_email: string;
  content: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export type AlertCommentWireOut = Pick<
  AlertComment,
  'user_id' | 'user_name' | 'user_email' | 'content'
>;

export interface AlertLogEvent {
  id: string;
  source: Record<string, string> & {
    '@timestamp'?: string;
  };
  '@timestamp'?: string | number;
  log: string;
}

export const CRITICALITY = ['Information', 'Low', 'Medium', 'High', 'Critical'] as const;
type Criticality = (typeof CRITICALITY)[number];

export const STATUS = ['open', 'acknowledged', 'closed'] as const;
type Status = (typeof STATUS)[number];

export interface AlertWire {
  id: string;
  priority: number;
  severity: number;
  title: string;
  status: Status;
  description: string;
  type: string;
  source_type: string;
  created_by: number;
  assigned_to: number;
  closed_by: number;
  context_id: number;
  playbook_id: string;
  integration: string;
  devices: string[];
  networks: string[];
  scans: string[];
  discovered_hosts: null | string[];
  /**
   * For event list (GET /v1/alerts) it is null, for alert details (GET /v1/alerts/:alertId) it is defined
   */
  events: Pick<AlertLogEvent, 'id' | 'source'>[] | null;
  cves: string[];
  group_by: { Key: string; Value: string }[];
  created_at: string;
  updated_at: string;
  closed_at: string;
  assigned_at: string;
}

export interface AlertStep {
  title: string;
  content: string;
  timestamp: string;
}

export interface AlertAnalysis {
  summary: string;
  steps: AlertStep[];
  risk: string;
  conclusion: string;
  threat_intellicence: string;
}

export interface AlertHierarchyNode {
  name: string;
  value: number;
  color: string;
  shape: string;
  imgSrc: string;
  linkWith: string[] | null;
  children: AlertHierarchyNode[] | null;
}

export interface Alert
  extends Omit<AlertWire, 'created_at' | 'updated_at' | 'closed_at' | 'assigned_at'> {
  severity: 0 | 1 | 2 | 3 | 4;
  resolved_by?: number;
  created_at: Date;
  updated_at: Date;
  closed_at?: Date;
  assigned_at?: Date;

  criticality: Criticality;
  contextId?: number;
  contextName?: string;
  sourceType: string;
  UserCreatedBy: number;
  assignedTo: number;
  UserResolvedBy: number;
  events: AlertLogEvent[] | null;
  /** Formatted created_at */
  // createdAt: string;
  // createdAtTimestmap: string;
  /** Formatted updated_at if not zero ('0001-01-01T00:00:00Z'), otherwise formatted created_at */
  // updatedAt: string;
  // updatedAtTimestamp: string;
  /**
   * Event object (list of key value pairs) that originated the alert
   */
  source: string;
  comments?: AlertComment[];
  analysis?: AlertAnalysis;
  attack_flow?: AlertHierarchyNode[];
  detailed_threats?: DetailedThreats;
  threat_intelligence?: ThreatIntelligence[];
}

export function formatAlertLogDate(
  input?: string | number | Date,
  dateFmt?: Partial<Intl.DateTimeFormatOptions>,
  timeFmt?: Partial<Intl.DateTimeFormatOptions>
): string {
  const timestamp = typeof input === 'string' ? parseInt(input, 10) : input;
  if (!timestamp) {
    return 'no date';
  }
  const date = new Date(timestamp);
  return formatAlertDate(date, dateFmt, timeFmt);
}

export function formatAlertDate(
  input: string | Date,
  dateFmt?: Partial<Intl.DateTimeFormatOptions>,
  timeFmt?: Partial<Intl.DateTimeFormatOptions>
): string {
  const date = new Date(input);

  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    ...timeFmt,
  };
  const timeString = new Intl.DateTimeFormat('en-US', optionsTime).format(date);

  const optionsDate: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    ...dateFmt,
  };
  const dateString = new Intl.DateTimeFormat('en-US', optionsDate).format(date);

  return `${timeString} | ${dateString}`;
}

function severityWireInternal(severity: number): 0 | 1 | 2 | 3 | 4 {
  severity = Math.floor(severity);
  if (severity < 0) {
    return 0;
  }
  if (severity > 4) {
    return 4;
  }
  return severity as 0 | 1 | 2 | 3 | 4;
}

export function alertWireToInternal(
  alert: AlertWire,
  user?: User,
  dateFmt?: Partial<Intl.DateTimeFormatOptions>,
  timeFmt?: Partial<Intl.DateTimeFormatOptions>
): Alert {
  const source = alert.group_by
    .map((item: { Key: string; Value: string }) => `${item.Key}: ${item.Value}`)
    .join(', ');
  return {
    ...alert,
    events:
      alert.events?.map((event) => ({
        ...event,
        '@timestamp': event.source['@timestamp'],
        log: Object.values(event.source).join(' '),
        // log: JSON.stringify(event.source, null, 2),
      })) ?? null,
    // {
    //   0: 'Critical',
    //   1: 'High',
    //   2: 'Medium',
    //   3: 'Low',
    //   4: 'Information',
    // }[alert.severity] as Criticality,
    severity: severityWireInternal(alert.severity),
    criticality: CRITICALITY[alert.severity - 1],
    sourceType: alert.source_type,
    UserCreatedBy: alert.created_by,
    assignedTo: alert.assigned_to,
    UserResolvedBy: alert.closed_by,
    contextId: alert.context_id,
    contextName: user?.userContexts?.find((context) => context.id === alert.context_id)?.name,
    created_at: new Date(alert.created_at),
    updated_at: new Date(
      alert.updated_at === '0001-01-01T00:00:00Z' ? alert.created_at : alert.updated_at
    ),
    closed_at: alert.closed_at === '0001-01-01T00:00:00Z' ? undefined : new Date(alert.closed_at),
    assigned_at:
      alert.assigned_at === '0001-01-01T00:00:00Z' ? undefined : new Date(alert.assigned_at),
    // createdAt: formatAlertDate(alert.created_at, dateFmt, timeFmt),
    // createdAtTimestmap: convertDateToTimestamp(alert.created_at),
    // updatedAt:
    //   // Use createdAt value when updatedAt is '0001-01-01T00:00:00Z'
    //   formatAlertDate(
    //     alert.updated_at === '0001-01-01T00:00:00Z' ? alert.created_at : alert.updated_at,
    //     dateFmt,
    //     timeFmt
    //   ),
    // updatedAtTimestamp:
    //   alert.updated_at === '0001-01-01T00:00:00Z'
    //     ? convertDateToTimestamp(alert.created_at) // Use createdAt value when updatedAt is '0001-01-01T00:00:00Z'
    //     : convertDateToTimestamp(alert.updated_at),
    // resolvedAt: !!alert.closed_at ? formatAlertDate(alert.closed_at) : '',
    // resolvedAtTimestamp: !!alert.closed_at ? convertDateToTimestamp(alert.closed_at) : '',
    source,
  };
}

export interface AlertKPIWire {
  alert_kpis: {
    [key in '1' | '2' | '3' | '4' | '5']: {
      count: number;
      variation: number;
    };
  };
  message: 'success';
}

export type AlertKPI = {
  [key in Criticality]?: { count: number; variation: number };
} & {
  closedAlerts: number;
};
export function alertKPIWireToInternal(alertsKPIs: AlertKPIWire['alert_kpis']): AlertKPI {
  const closedAlerts =
    alertsKPIs['1'].count +
    alertsKPIs['2'].count +
    alertsKPIs['3'].count +
    alertsKPIs['4'].count +
    alertsKPIs['5'].count;
  return {
    Information: alertsKPIs['1'],
    Low: alertsKPIs['2'],
    Medium: alertsKPIs['3'],
    High: alertsKPIs['4'],
    Critical: alertsKPIs['5'],
    closedAlerts,
  };
}
