import { createContext, useReducer, useCallback, useMemo } from 'react';

import axios from '../../utils/axios';
import localStorageAvailable from '../../utils/localStorageAvailable';

import {
  ActionMapType,
  EndpointResourceType,
  EndpointServiceType,
  EndpointProcessType,
  EndpointStorageType,
  NetworkType,
  EndpointInstalledType,
  EndpointInstallDataType,
  ThreatMap,
  Device,
  EndpointStateType,
  EndpointContextType,
  ComplianceKPI,
  ThreatsKPI,
} from './EndpointsContextTypes';
import { IntegrationsType } from '../integrations/IntegrationsContextTypes';

enum Types {
  INITIAL = 'INITIAL',
  SET_DEVICE = 'GET_DEVICE',
  SET_DEVICES_BY_CONTEXT = 'GET_DEVICES_BY_CONTEXT',
  SET_ENDPOINT_INSTALL_DATA = 'SET_ENDPOINT_INSTALL_DATA',
  SET_THREATS_ACTIVITY_MAP = 'SET_THREATS_ACTIVITY_MAP',
  SET_ENDPOINT_RESOURCES = 'SET_ENDPOINT_RESOURCES',
  SET_ENDPOINT_COMPLIANCE_KPIS = 'SET_ENDPOINT_COMPLIANCE_KPIS',
  SET_ENDPOINT_THREATS_KPIS = 'SET_ENDPOINT_THREATS_KPIS',
}

type PayLoad = {
  [Types.INITIAL]: {
    isInitialized: boolean;
    endpoints: Device[];
  };
  [Types.SET_DEVICE]: {
    selectedEndpoint: Device;
  };
  [Types.SET_DEVICES_BY_CONTEXT]: {
    endpoints: Device[];
  };
  [Types.SET_ENDPOINT_INSTALL_DATA]: {
    selectedEndpointInstallData: EndpointInstallDataType;
  };
  [Types.SET_THREATS_ACTIVITY_MAP]: {
    selectedEndpointThreatMap: ThreatMap;
  };
  [Types.SET_ENDPOINT_RESOURCES]: {
    resources: EndpointResourceType[];
    services: EndpointServiceType[];
    processes: EndpointProcessType[];
    installed: EndpointInstalledType[];
    network: NetworkType[];
    storage: EndpointStorageType[];
    integrations: IntegrationsType;
    threats: ThreatsKPI;
  };
  [Types.SET_ENDPOINT_COMPLIANCE_KPIS]: {
    selectedEndpointComplianceKPI: ComplianceKPI;
  };
  [Types.SET_ENDPOINT_THREATS_KPIS]: {
    selectedEndpointThreatsKPI: ThreatsKPI;
  };
};

type ActionsType = ActionMapType<PayLoad>[keyof ActionMapType<PayLoad>];

const initialState: EndpointStateType = {
  isInitialized: false,
  endpoints: [],
  selectedEndpointThreatMap: {
    risk_threshold: 0,
    apps: [],
    networks: [],
    files: [],
  },
  selectedEndpointResources: [],
  selectedEndpointThreats: {
    detected: 0,
    resolved: 0,
  },
  selectedEndpointServices: [],
  selectedEndpointProcesses: [],
  selectedEndpointInstalled: [],
  selectedEndpointNetwork: [],
  selectedEndpointStorage: [],
  selectedEndpointInstallData: {
    id: '',
    command: '',
    url: '',
    token: '',
  },
  selectedEndpointComplianceKPI: {
    audit: 0,
    vulnerabilities: 0,
  },
  selectedEndpointThreatsKPI: {
    detected: 0,
    resolved: 0,
  },
  selectedEndpointIntegrations: [],
};

const reducer = (state: EndpointStateType, action: ActionsType): EndpointStateType => {
  switch (action.type) {
    case Types.INITIAL: {
      return {
        ...state,
      };
    }
    case Types.SET_DEVICE: {
      return {
        ...state,
        selectedEndpoint: action.payload.selectedEndpoint,
      };
    }
    case Types.SET_DEVICES_BY_CONTEXT: {
      return {
        ...state,
        endpoints: action.payload.endpoints,
      };
    }
    case Types.SET_ENDPOINT_INSTALL_DATA: {
      return {
        ...state,
        selectedEndpointInstallData: action.payload.selectedEndpointInstallData,
      };
    }
    case Types.SET_THREATS_ACTIVITY_MAP: {
      return {
        ...state,
        selectedEndpointThreatMap: action.payload.selectedEndpointThreatMap,
      };
    }
    case Types.SET_ENDPOINT_RESOURCES: {
      return {
        ...state,
        selectedEndpointResources: action.payload.resources,
        selectedEndpointThreats: action.payload.threats,
        selectedEndpointServices: action.payload.services,
        selectedEndpointProcesses: action.payload.processes,
        selectedEndpointInstalled: action.payload.installed,
        selectedEndpointNetwork: action.payload.network,
        selectedEndpointStorage: action.payload.storage,
      };
    }
    case Types.SET_ENDPOINT_COMPLIANCE_KPIS: {
      return {
        ...state,
        selectedEndpointComplianceKPI: action.payload.selectedEndpointComplianceKPI,
      };
    }
    case Types.SET_ENDPOINT_THREATS_KPIS: {
      return {
        ...state,
        selectedEndpointThreatsKPI: action.payload.selectedEndpointThreatsKPI,
      };
    }
    default:
      return state;
  }
};

export const EndpointsContext = createContext<EndpointContextType | null>(null);

type ContextProviderProps = {
  children: React.ReactNode;
};

export function EndpointsProvider({ children }: ContextProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const getDevice: EndpointContextType['getDevice'] = useCallback(
    async (contexts: string[], id: string) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      if (!accessToken) {
        throw new Error('No access  token found');
      }

      const response = await axios.get(`/v1/devices/${id}`, {
        params: {
          contexts: contexts.join(','),
        },
      });

      dispatch({
        type: Types.SET_DEVICE,
        payload: {
          selectedEndpoint: response.data,
        },
      });
    },
    [storageAvailable]
  );

  const getDevicesByContext: EndpointContextType['getDevicesByContext'] = useCallback(
    async (contexts: string[]) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      if (!accessToken) {
        throw new Error('No access  token found');
      }

      const response = await axios.get('/v1/devices', {
        params: {
          contexts: contexts.join(','),
        },
      });

      dispatch({
        type: Types.SET_DEVICES_BY_CONTEXT,
        payload: {
          endpoints: response.data,
        },
      });
    },
    [storageAvailable]
  );

  const createEndpoint: EndpointContextType['createEndpoint'] = useCallback(
    async (
      contexts: string[],
      isTemplate: boolean,
      device_name: string,
      description: string,
      os: string,
      distribution: string,
      arch: string,
      severity: number,
      valid_minutes: number
    ) => {
      try {
        const acessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

        if (!acessToken) {
          return;
        }

        const response = await axios.post(
          '/v1/devices',
          {
            device_name,
            description,
            os,
            distribution,
            arch,
            severity,
            valid_minutes,
          },
          {
            params: {
              contexts: contexts.join(','),
              isTemplate: isTemplate,
            },
          }
        );

        dispatch({
          type: Types.SET_ENDPOINT_INSTALL_DATA,
          payload: {
            selectedEndpointInstallData: response.data,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
      }
    },
    [storageAvailable]
  );

  const getEndpointDetails: EndpointContextType['getEndpointDetails'] = useCallback(
    async (endpointId: string, context: string) => {
      try {
        const acessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

        if (!acessToken) {
          return;
        }

        const dashboardAgentData = await axios.get(
          `/v1/devices/dashboard/agentData/${endpointId}`,
          {
            params: {
              contexts: context,
            },
          }
        );
        /*const integrationsResponse = await axios.get('/v1/integrations/', {
          params: {
            contexts: context,
            endpoint_id: endpointId,
          },
        });*/

        dispatch({
          type: Types.SET_ENDPOINT_RESOURCES,
          payload: {
            resources: dashboardAgentData.data.resources || [],
            services: dashboardAgentData.data.services || [],
            processes: dashboardAgentData.data.process || [],
            installed: dashboardAgentData.data.installed || [],
            network: dashboardAgentData.data.network || [],
            storage: dashboardAgentData.data.storage || [],
            threats: dashboardAgentData.data.threats || [],
            integrations: dashboardAgentData.data || [],
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [storageAvailable]
  );

  const getThreatsMap: EndpointContextType['getThreatsMap'] = useCallback(
    async (deviceId: string, context: string) => {
      try {
        const acessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

        if (!acessToken) {
          return;
        }

        const response = await axios.get(`/v1/devices/threat-map/${deviceId}`, {
          params: {
            contexts: context,
          },
        });

        dispatch({
          type: Types.SET_THREATS_ACTIVITY_MAP,
          payload: {
            selectedEndpointThreatMap: response.data.threat_map,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
      }
    },
    [storageAvailable]
  );

  const getComplianceKPIs: EndpointContextType['getComplianceKPIs'] = useCallback(
    async (deviceId: string, context: string) => {
      try {
        const acessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

        if (!acessToken) {
          return;
        }

        const response = await axios.get(`/v1/devices/compliance-kpis/${deviceId}`, {
          params: {
            contexts: context,
          },
        });

        dispatch({
          type: Types.SET_ENDPOINT_COMPLIANCE_KPIS,
          payload: {
            selectedEndpointComplianceKPI: response.data.compliance,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
      }
    },
    [storageAvailable]
  );

  const getThreatsKPIs: EndpointContextType['getThreatsKPIs'] = useCallback(
    async (deviceId: string, context: string) => {
      try {
        const acessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

        if (!acessToken) {
          return;
        }

        const response = await axios.get(`/v1/devices/threats-kpis/${deviceId}`, {
          params: {
            contexts: context,
          },
        });

        dispatch({
          type: Types.SET_ENDPOINT_THREATS_KPIS,
          payload: {
            selectedEndpointThreatsKPI: response.data.threats,
          },
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
      }
    },
    [storageAvailable]
  );

  const changeEndpointDetails = useCallback(
    async (
      deviceId: string,
      context: string[],
      updateData: Partial<{
        name: string;
        severity: number;
        description: string;
        authorized: boolean;
        xdr: boolean;
        pdr: boolean;
        mdr: boolean;
        collector: boolean;
      }>
    ) => {
      try {
        const acessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

        if (!acessToken) {
          return;
        }

        await axios.patch(
          `/v1/devices/${deviceId}`,
          updateData, // Pass the entire updateData object
          {
            params: {
              contexts: context.join(','),
            },
          }
        );
      } catch (error) {
        console.error(error);

        if (error.response) {
          console.error(error.response.data);
        }
      }
    },
    [storageAvailable]
  );

  const cleanUp = () => {
    dispatch({
      type: Types.SET_ENDPOINT_RESOURCES,
      payload: {
        resources: [],
        services: [],
        processes: [],
        installed: [],
        network: [],
        storage: [],
        threats: { detected: 0, resolved: 0 },
        integrations: [],
      },
    });
  };

  const memoizedValue = useMemo<EndpointContextType>(
    () => ({
      ...state,
      getEndpointDetails,
      changeEndpointDetails,
      getThreatsKPIs,
      getComplianceKPIs,
      getDevice,
      getThreatsMap,
      createEndpoint,
      getDevicesByContext,
      cleanUp,
    }),
    [
      state,
      getEndpointDetails,
      changeEndpointDetails,
      getThreatsKPIs,
      getComplianceKPIs,
      getDevice,
      getThreatsMap,
      createEndpoint,
      getDevicesByContext,
      cleanUp,
    ]
  );
  return <EndpointsContext.Provider value={memoizedValue}>{children}</EndpointsContext.Provider>;
}
