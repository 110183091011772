import { Typography, Box, TextField, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { useContextProvider } from 'src/context/useContextProvider';
import { isToday, isYesterday, subDays, isAfter, format } from 'date-fns';
import Iconify from 'src/components/iconify';
import { useLocation } from 'react-router-dom';
import { useLocales } from 'src/locales';

export default function ChatHistory() {
  const location = useLocation();
  const { getChatList, messagesList, getChatHistory, messagesHistory } = useContextProvider();
  const [searchTerm, setSearchTerm] = useState('');
  const { translate } = useLocales();

  useEffect(() => {
    async function fetchChatList() {
      await getChatList();
    }
    fetchChatList();
  }, [getChatList, messagesHistory]);

  const filterMessages = (messages: any[]) =>
    messages
      .filter((message) => message.name.trim() !== '')
      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

  const searchMessages = (messages: any[]) =>
    messages.filter((message) => {
      const formattedDate = format(new Date(message.created_at), 'dd/MM/yyyy');
      return (
        message.conversation_id.includes(searchTerm) ||
        message.name.includes(searchTerm) ||
        formattedDate.includes(searchTerm)
      );
    });

  const today = new Date();
  const todayMessages = searchMessages(
    filterMessages(
      Array.isArray(messagesList)
        ? messagesList.filter((message) => {
            const messageDate = new Date(message.created_at);
            return (
              messageDate.getDate() === today.getDate() &&
              messageDate.getMonth() === today.getMonth() &&
              messageDate.getFullYear() === today.getFullYear()
            );
          })
        : []
    )
  );
  const yesterday = subDays(today, 1);
  const yesterdayMessages = searchMessages(
    filterMessages(
      Array.isArray(messagesList)
        ? messagesList.filter((message) => {
            const messageDate = new Date(message.created_at);
            return (
              messageDate.getDate() === yesterday.getDate() &&
              messageDate.getMonth() === yesterday.getMonth() &&
              messageDate.getFullYear() === yesterday.getFullYear()
            );
          })
        : []
    )
  );
  const last7DaysMessages = searchMessages(
    filterMessages(
      Array.isArray(messagesList)
        ? messagesList.filter(
            (message) =>
              isAfter(new Date(message.created_at), subDays(new Date(), 7)) &&
              !isToday(new Date(message.created_at)) &&
              !isYesterday(new Date(message.created_at))
          )
        : []
    )
  );
  const last30DaysMessages = searchMessages(
    filterMessages(
      Array.isArray(messagesList)
        ? messagesList.filter(
            (message) =>
              isAfter(new Date(message.created_at), subDays(new Date(), 30)) &&
              !isToday(new Date(message.created_at)) &&
              !isYesterday(new Date(message.created_at)) &&
              !isAfter(new Date(message.created_at), subDays(new Date(), 7))
          )
        : []
    )
  );

  const boxStyle = () => ({
    maxHeight: 'auto',
    overflowY: 'visible',
    wordWrap: 'break-word',
    marginBottom: 1,
  });

  const messageStyle = {
    padding: 1.25,
    fontSize: '14px',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    cursor: 'pointer',
    borderRadius: '8px',
    transition: 'box-shadow 0.3s ease-in-out',
    marginLeft: '5px',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
  };

  const handleClickMessage = async (conversationId: string, name: string) => {
    localStorage.removeItem('conversationId');
    localStorage.removeItem('messages');
    localStorage.removeItem('firstMessage');
    await getChatHistory(conversationId, 10);
    localStorage.setItem('conversationId', conversationId);
    localStorage.setItem('messages', name);
  };

  return (
    <Box sx={{ margin: 1 }}>
      <Typography sx={{ padding: 1, marginTop: 3, fontWeight: 'bold', fontSize: '14px' }}>
        {translate('chatHistory.title' || 'Chat History')}
      </Typography>
      <TextField
        placeholder={`${translate('chatHistory.searchPlaceholder' || 'Search')}`}
        variant="outlined"
        size="small"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{
          marginLeft: '5px',
          marginBottom: 3,
          width: '90%',
          borderRadius: '8px',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />
      <Typography sx={{ padding: 0.75, fontWeight: 'bold', fontSize: '14px' }}>
        {translate('chatHistory.today' || 'Today')}
      </Typography>
      <Box sx={boxStyle()}>
        {todayMessages.map((message) => (
          <Typography
            key={message.id}
            sx={messageStyle}
            onClick={() => handleClickMessage(message.conversation_id, message.name)}
            title={message.name}
          >
            {message.name}
          </Typography>
        ))}
      </Box>
      <Typography sx={{ padding: 0.75, fontWeight: 'bold', fontSize: '14px' }}>
        {translate('chatHistory.yesterday' || 'Yesterday')}
      </Typography>
      <Box sx={boxStyle()}>
        {yesterdayMessages.map((message) => (
          <Typography
            key={message.id}
            sx={messageStyle}
            onClick={() => handleClickMessage(message.conversation_id, message.name)}
            title={message.name}
          >
            {message.name}
          </Typography>
        ))}
      </Box>
      <Typography sx={{ padding: 0.75, fontWeight: 'bold', fontSize: '14px' }}>
        {translate('chatHistory.last7Days' || 'Last 7 days')}
      </Typography>
      <Box sx={boxStyle()}>
        {last7DaysMessages.map((message) => (
          <Typography
            key={message.id}
            sx={messageStyle}
            onClick={() => handleClickMessage(message.conversation_id, message.name)}
            title={message.name}
          >
            {message.name}
          </Typography>
        ))}
      </Box>
      <Typography sx={{ padding: 0.75, fontWeight: 'bold', fontSize: '14px' }}>
        {translate('chatHistory.last30Days' || 'Last 30 days')}
      </Typography>
      <Box sx={boxStyle()}>
        {last30DaysMessages.map((message) => (
          <Typography
            key={message.id}
            sx={messageStyle}
            onClick={() => handleClickMessage(message.conversation_id, message.name)}
            title={message.name}
          >
            {message.name}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}
