import {
  ActionMapType,
  ChatMessage,
  ContextDiskType,
  ContextPackagesType,
  ContextPointsType,
  ContextProviderType,
  ContextStateType,
  ContextType,
  ContractType,
  DomainType,
  GroupType,
  HistoryLLM,
  ConversationList,
  MessageLLM,
  OrganizationType,
  PatchContext,
  PatchOrganization,
  PoliciesTypes,
  SubOrganizationType,
  UserType,
} from './ContextTypes';
import { ContextsItem, GETManagerOverview, POSTOrganizationInvite } from './ContextApi';
import { GenericErrorResponse, wrapAxios } from '../utils/axios-api';
import { InGetUsersByOrganization, isUserEntity, userWireToInternal } from '../auth/UserApi';
import { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';

import { AxiosResponse } from 'axios';
import axios from '../utils/axios';
import { resultAsync } from '../utils/result';
import { useJwtAuthContext } from '../auth/useAuthContext';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  GET_CONTEXT = 'GET_CONTEXT',
  GET_CONTEXTS = 'GET_CONTEXTS',
  GET_CONTEXTS_POINTS = 'GET_CONTEXTS_POINTS',
  GET_CONTEXTS_PACKAGES = 'GET_CONTEXTS_PACKAGES',
  GET_CONTEXTS_DISK = 'GET_CONTEXTS_DISK',
  CREATE_CONTEXTS = 'CREATE_CONTEXTS',
  PATCH_CONTEXTS = 'PATCH_CONTEXTS',
  SET_SELECTED_CONTEXTS = 'SET_SELECTED_CONTEXTS', // Change here
  GET_USERS_FROM_CONTEXT = 'GET_USERS_FROM_CONTEXT',
  /** @deprecated unused */
  SET_CONTEXTS_IN_USER = 'SET_CONTEXTS_IN_USER',
  GET_ORGANIZATION = 'GET_ORGANIZATION',
  GET_USER_ORGANIZATION = 'GET_USER_ORGANIZATION',
  GET_DOMAIN = 'GET_DOMAIN',
  GET_DOMAINS = 'GET_DOMAINS',
  GET_GROUPS_FROM_CONTEXT = 'GET_GROUPS_FROM_CONTEXT',
  PATCH_ORGANIZATIONS = 'PATCH_ORGANIZATIONS',
  GET_ORGANIZATION_USERS = 'GET_ORGANIZATION_USERS',
  GET_SUB_ORGANIZATION_CONTRACT = 'GET_SUB_ORGANIZATION_CONTRACT',
  GET_SUB_ORGANIZATION = 'GET_SUB_ORGANIZATION',
  GET_POLICIES = 'GET_POLICIES',
  GET_CHAT_LIST = 'GET_CHAT_LIST',
  GET_CHAT_HISTORY = 'GET_CHAT_HISTORY',
  GET_NEW_CHAT = 'GET_NEW_CHAT',
  POST_LLM_RESPONSE = 'POST_LLM_RESPONSE',
}

type Payload = {
  [Types.INITIAL]: Partial<ContextStateType>;
  [Types.GET_CONTEXT]: { context: ContextType };
  [Types.GET_CONTEXTS]: { contexts: ContextType[] };
  [Types.GET_CONTEXTS_POINTS]: { contextPoints: ContextPointsType };
  [Types.GET_CONTEXTS_PACKAGES]: { contextPackages: ContextPackagesType };
  [Types.GET_CONTEXTS_DISK]: { contextDisk: ContextDiskType };
  [Types.SET_SELECTED_CONTEXTS]: Pick<ContextStateType, 'selectedContexts'>;
  [Types.GET_USERS_FROM_CONTEXT]: { usersFromContext: UserType[] }; // Assume User type is defined
  [Types.CREATE_CONTEXTS]: { contexts: ContextType[] };
  [Types.PATCH_CONTEXTS]: { updateContexts: PatchContext[] };
  [Types.GET_ORGANIZATION]: { organization: OrganizationType };
  [Types.GET_USER_ORGANIZATION]: { userOrganization: OrganizationType };
  [Types.GET_DOMAIN]: { domain: DomainType };
  [Types.GET_DOMAINS]: { domains: DomainType[] };
  [Types.GET_GROUPS_FROM_CONTEXT]: { groups: GroupType[] };
  /** @deprecated unused */
  [Types.SET_CONTEXTS_IN_USER]: { contextsInUser: ContextType[] };
  [Types.PATCH_ORGANIZATIONS]: { updateOrganization: PatchOrganization[] };
  [Types.GET_ORGANIZATION_USERS]: Pick<ContextStateType, 'organizationUsers'>;
  [Types.GET_SUB_ORGANIZATION_CONTRACT]: { contract: ContractType[] };
  [Types.GET_SUB_ORGANIZATION]: { subOrganization: SubOrganizationType[] };
  [Types.GET_POLICIES]: { policies: PoliciesTypes[] };
  [Types.GET_CHAT_LIST]: { messagesList: ConversationList[] };
  [Types.GET_CHAT_HISTORY]: { messagesHistory: HistoryLLM };
  [Types.GET_NEW_CHAT]: { newChat: MessageLLM };
  [Types.POST_LLM_RESPONSE]: { createLLM: ChatMessage };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

function reducer(state: ContextStateType, action: ActionsType): ContextStateType {
  switch (action.type) {
    case Types.INITIAL:
      return {
        ...state,
        ...action.payload,
        isInitialized: true,
      };
    case Types.GET_CONTEXT:
      return {
        ...state,
        context: action.payload.context,
      };
    case Types.GET_CONTEXTS:
      return {
        ...state,
        contexts: action.payload.contexts,
      };
    case Types.GET_DOMAIN:
      return {
        ...state,
        domain: action.payload.domain,
      };
    case Types.GET_DOMAINS:
      return {
        ...state,
        domains: action.payload.domains,
      };
    case Types.SET_SELECTED_CONTEXTS:
      return {
        ...state,
        selectedContexts: action.payload.selectedContexts,
      };
    case Types.GET_USERS_FROM_CONTEXT:
      return {
        ...state,
        usersFromContext: action.payload.usersFromContext,
      };
    case Types.GET_CONTEXTS_POINTS:
      return {
        ...state,
        contextPoints: action.payload.contextPoints,
      };
    case Types.GET_CONTEXTS_PACKAGES:
      return {
        ...state,
        contextPackages: action.payload.contextPackages,
      };
    case Types.GET_CONTEXTS_DISK:
      return {
        ...state,
        contextDisk: action.payload.contextDisk,
      };
    case Types.CREATE_CONTEXTS:
      return {
        ...state,
        contexts: action.payload.contexts,
      };
    case Types.PATCH_CONTEXTS:
      return {
        ...state,
        updateContexts: action.payload.updateContexts,
      };
    case Types.GET_ORGANIZATION:
      return {
        ...state,
        organization: action.payload.organization,
      };
    case Types.GET_USER_ORGANIZATION:
      return {
        ...state,
        userOrganization: action.payload.userOrganization,
      };
    case Types.SET_CONTEXTS_IN_USER:
      return {
        ...state,
        contextsInUser: action.payload.contextsInUser,
      };
    case Types.PATCH_ORGANIZATIONS:
      return {
        ...state,
        updateOrganization: action.payload.updateOrganization,
      };
    case Types.GET_ORGANIZATION_USERS:
      return {
        ...state,
        organizationUsers: action.payload.organizationUsers,
      };
    case Types.GET_SUB_ORGANIZATION_CONTRACT:
      return {
        ...state,
        contract: action.payload.contract,
      };
    case Types.GET_SUB_ORGANIZATION:
      return {
        ...state,
        subOrganization: action.payload.subOrganization,
      };
    case Types.GET_GROUPS_FROM_CONTEXT:
      return {
        ...state,
        groups: action.payload.groups,
      };
    case Types.GET_POLICIES:
      return {
        ...state,
        policies: action.payload.policies,
      };
    case Types.GET_CHAT_LIST:
      return {
        ...state,
        messagesList: action.payload.messagesList,
      };
    case Types.GET_CHAT_HISTORY:
      return {
        ...state,
        messagesHistory: action.payload.messagesHistory,
      };
    case Types.GET_NEW_CHAT:
      return {
        ...state,
        newChat: action.payload.newChat,
      };
    case Types.POST_LLM_RESPONSE:
      return {
        ...state,
        createLLM: action.payload.createLLM,
      };
  }
  function exhaustiveGuard(_value: never): never {
    throw new Error(
      `ERROR! Reached forbidden guard function with unexpected value: ${JSON.stringify(_value)}`
    );
  }
  exhaustiveGuard(action);
  return state;
}

export const ContextProvider = createContext<ContextProviderType | null>(null);

type ContextProviderProps = {
  children: React.ReactNode;
};

function storeSelectedContexts(userId: number, contexts?: ContextsItem[]): ContextsItem[] {
  if (!userId) {
    return [];
  }
  const key = `selectedContexts-${userId}`;
  try {
    if (contexts) {
      localStorage.setItem(key, JSON.stringify(contexts));
    }
    return JSON.parse(localStorage.getItem(key) || '[]');
  } catch (error) {
    return [];
  }
}

// É necessario definir o estado inicial separadamente fora do useReducer !!
const initialState: ContextStateType = {
  contexts: [],
  organizations: [],
  groups: [],
  domains: [],
  isInitialized: false,
  contextsInUser: [],
  usersFromContext: [],
  contextPoints: {},
  contextDisk: {},
  updateContexts: [],
  updateOrganization: [],
  organizationUsers: [],
  subOrganization: [],
  createOrganizations: [],
  policies: [],
  selectedContexts: [],
  contract: [],
  messagesList: [],
};

export function ContextProviderMethods({ children }: ContextProviderProps) {
  const { user } = useJwtAuthContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (user?.id) {
      dispatch({
        type: Types.INITIAL,
        payload: { selectedContexts: storeSelectedContexts(user.id) },
      });
    }
  }, [user]);

  const getContextsPoints: ContextProviderType['getContextsPoints'] = useCallback(
    async (context?: number[]) => {
      try {
        const response = await axios.get('/v1/manager/points?', {
          params: {
            contexts: context?.join(','),
          },
        });
        dispatch({
          type: Types.GET_CONTEXTS_POINTS,
          payload: {
            contextPoints: {
              available: response.data.points.available,
              consumed_month: response.data.points.consumed_month,
              consumed_today: response.data.points.consumed_today,
              consumed_week: response.data.points.consumed_week,
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const getContextsPackages: ContextProviderType['getContextsPackages'] = useCallback(
    async (context?: number[]) => {
      try {
        const response = await axios.get('/v1/manager/packages?', {
          params: {
            contexts: context?.join(','),
          },
        });
        dispatch({
          type: Types.GET_CONTEXTS_PACKAGES,
          payload: {
            contextPackages: {
              device: response.data.packages.devices,
              integration: response.data.packages.integrations,
              mdr: response.data.packages.mdr,
              xdr: response.data.packages.xdr,
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const getContextsDisk: ContextProviderType['getContextsDisk'] = useCallback(
    async (organizationID: number, contexts) => {
      try {
        const response = await axios.get(`/v1/manager/organizations/${organizationID}/disk`, {
          params: {
            contexts: contexts?.join(','),
          },
        });
        if (response?.data?.disk === undefined) {
          throw new Error('Disk data not found');
        }
        const contextDisk: ContextDiskType = {
          available: response.data.disk.available,
          used: response.data.disk.used,
        };
        dispatch({
          type: Types.GET_CONTEXTS_DISK,
          payload: { contextDisk },
        });
        return contextDisk;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    []
  );

  const getContextsFromDomain: ContextProviderType['getContextsFromDomain'] = useCallback(
    async (domains: string[]) => {
      try {
        const response = await axios.get('/v1/manager/contexts', {
          params: {
            /** @deprecated there is no handling of domains query param on GetContexts */
            domains: domains.join(','),
          },
        });
        dispatch({
          type: Types.GET_CONTEXTS,
          payload: {
            contexts: response.data.contexts,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const getUsersFromDomain: ContextProviderType['getUsersFromDomain'] = useCallback(
    async (domain: string[]) => {
      try {
        const response = await axios.get('/v1/users', {
          params: {
            domain_id: domain.join(','),
          },
        });
        dispatch({
          type: Types.GET_USERS_FROM_CONTEXT,
          payload: {
            usersFromContext: response.data.users,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const getDomain: ContextProviderType['getDomain'] = useCallback(
    async (domainID: number, contexts) => {
      try {
        const response = await axios.get(`/v1/manager/domains/${domainID}`, {
          params: {
            contexts: contexts.join(','),
          },
        });

        const orgResponse = await axios.get(
          `/v1/manager/organizations/${response.data.OrganizationID}`,
          {
            params: {
              contexts: contexts.join(','),
            },
          }
        );

        dispatch({
          type: Types.GET_DOMAIN,
          payload: {
            domain: {
              ...response.data,
              Organization: orgResponse.data,
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const getOrganization: ContextProviderType['getOrganization'] = useCallback(
    async (organizationID: number, contexts) => {
      if (organizationID === state.organization?.ID) {
        return;
      }
      try {
        const response = await axios.get(`/v1/manager/organizations/${organizationID}`, {
          params: {
            contexts: contexts.join(','),
          },
        });
        dispatch({
          type: Types.GET_ORGANIZATION,
          payload: {
            organization: response.data,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [state.organization?.ID]
  );

  const getUserOrganization: ContextProviderType['getUserOrganization'] = useCallback(
    async (organizationID: number, contexts: number[]) => {
      try {
        const response = await axios.get(`/v1/manager/organizations/${organizationID}`, {
          params: {
            contexts: contexts.join(','),
          },
        });
        dispatch({
          type: Types.GET_USER_ORGANIZATION,
          payload: {
            userOrganization: response.data.name,
          },
        });
      } catch (error) {
        console.log(error);
        return;
      }
    },
    []
  );

  const getOrganizationUsers: ContextProviderType['getOrganizationUsers'] = useCallback(
    async (organizationID: number, contexts: number[]) => {
      const response = await resultAsync<
        AxiosResponse<InGetUsersByOrganization, {}>,
        GenericErrorResponse
      >(
        axios.get(`/v1/manager/organizations/${organizationID}/users`, {
          params: { contexts: contexts.join(',') },
        })
      );
      if (!response.ok?.data?.users) {
        console.error(response);
        return;
      }
      const organizationUsers = response.ok.data.users
        .map((u) => userWireToInternal(u).ok)
        .filter(isUserEntity);
      dispatch({
        type: Types.GET_ORGANIZATION_USERS,
        payload: { organizationUsers },
      });
    },
    []
  );

  const getSubOrganizationContract: ContextProviderType['getSubOrganizationContract'] = useCallback(
    async (organizationID: number) => {
      try {
        const response = await axios.get(`/v1/manager/organizations/${organizationID}/contract`);

        dispatch({
          type: Types.GET_SUB_ORGANIZATION_CONTRACT,
          payload: {
            contract: response.data.data.contracts,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const createContract: ContextProviderType['createContract'] = useCallback(
    async (
      organizationID: number,
      product_ids: {
        1: number;
        2: number;
        3: number;
        4: number;
        5: number;
      },
      mssp_id: number,
      start_date: string,
      end_date: string
    ) => {
      try {
        const payload = { product_ids, mssp_id, start_date, end_date };
        await axios.post(`/v1/manager/organizations/${organizationID}/contract`, payload);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    []
  );

  const patchContract: ContextProviderType['patchContract'] = useCallback(
    async (
      organizationID: number,
      contract_id: number,
      product_ids: {
        1: number;
        2: number;
        3: number;
        4: number;
        5: number;
      },
      end_date?: string
    ) => {
      try {
        const payload = { product_ids, end_date };
        await axios.patch(
          `/v1/manager/organizations/${organizationID}/contract/${contract_id}?`,
          payload
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    []
  );

  const getSubOrganization: ContextProviderType['getOrganizationUsers'] = useCallback(
    async (organizationID: number, contexts?: number[]) => {
      try {
        const response = await axios.get(`/v1/manager/organizations/${organizationID}/sub-orgs`, {
          params: {
            contexts: contexts?.join(','),
          },
        });
        dispatch({
          type: Types.GET_SUB_ORGANIZATION,
          payload: {
            subOrganization: response.data.sub_organizations,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const patchOrganization: ContextProviderType['patchOrganization'] = useCallback(
    async (organizationID: number, updateOrganization?: PatchOrganization) => {
      const result = await wrapAxios<PatchOrganization, {}, GenericErrorResponse>(axios.patch)(
        `/v1/manager/organizations/${organizationID}`,
        {
          name: updateOrganization?.name || '',
          cnpj: updateOrganization?.cnpj || '',
          trade_name: updateOrganization?.trade_name || '',
          can_use_parent_org_points: updateOrganization?.can_use_parent_org_points || false,
        }
      );
      if (!result.ok) {
        console.error(result);
      }
      // return result;
    },
    []
  );

  const patchContexts: ContextProviderType['patchContexts'] = useCallback(
    async (context_id: string, updateContexts: PatchContext) => {
      try {
        await axios.patch(`/v1/manager/contexts/${context_id}`, {
          name: updateContexts?.Name,
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const patchAllowPoints: ContextProviderType['patchAllowPoints'] = useCallback(
    async (organization_id: number, read_write: string) => {
      try {
        await axios.patch(
          `/v1/manager/organizations/${organization_id}/allow-use-of-parent-points`,
          {
            read_write: read_write,
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const createContext = useCallback(async (contextName: string, domainId: number) => {
    try {
      const response = await axios.post('/v1/manager/contexts', {
        name: contextName,
        id: domainId,
      });
      dispatch({
        type: Types.CREATE_CONTEXTS,
        payload: {
          contexts: response.data.Contexts,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const updateUserToPolicy: ContextProviderType['updateUserToPolicy'] = useCallback(
    async (organizationID: number, userId: number) => {
      try {
        await axios.post(`/v1/manager/policies/users/${organizationID}?`, {
          user_id: userId,
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const getPolicies: ContextProviderType['getPolicies'] = useCallback(
    async (organizationID: number) => {
      try {
        const response = await axios.get(`/v1/manager/policies?`, {
          params: {
            organization_id: organizationID,
          },
        });
        dispatch({
          type: Types.GET_POLICIES,
          payload: {
            policies: response.data,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const createSubOrganizations: ContextProviderType['createSubOrganizations'] = useCallback(
    async (
      contexts,
      country: string,
      cnpj: string,
      name: string,
      trade_name: string,
      parent_id: string
    ) => {
      try {
        const payload = { country, cnpj, name, trade_name, parent_id };
        await axios.post('/v1/manager/organizations', payload, {
          params: { contexts: contexts.join(',') },
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const removeUserOrganizations: ContextProviderType['removeUserOrganizations'] = useCallback(
    async (organizationID: number, email: string) => {
      try {
        await axios.post(`/v1/manager/organizations/${organizationID}/remove?`, {
          email: email,
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const createNewUserInContext: ContextProviderType['createNewUserInContext'] = useCallback(
    async (contexts, name: string, password: string, email: string, groupId: number) => {
      try {
        await axios.post('/api/admin/user/create', {
          Name: name,
          Password: password,
          email,
          groupid: groupId,
          contexts: contexts,
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const inviteUserOrganizations: ContextProviderType['inviteUserOrganizations'] = useCallback(
    async (organizationID: number, email: string, group: boolean, contexts) => {
      try {
        await axios.post(
          `/v1/manager/organizations/${organizationID}/invite`,
          {
            email: email,
            read_write: group,
          },
          {
            params: { contexts: contexts.join(',') },
          }
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    []
  );

  const setSelectedContexts: ContextProviderType['setSelectedContexts'] = useCallback(
    async (contexts) => {
      if (!user?.id) {
        return;
      }
      storeSelectedContexts(user.id, contexts);
      dispatch({
        type: Types.SET_SELECTED_CONTEXTS,
        payload: {
          selectedContexts: contexts,
        },
      });
    },
    [user?.id]
  );

  const getUsersFromContext: ContextProviderType['getUsersFromContext'] = useCallback(
    async (context: string[]) => {
      try {
        const response = await axios.get('/v1/users', {
          params: {
            contexts: context.join(','),
          },
        });
        dispatch({
          type: Types.GET_USERS_FROM_CONTEXT,
          payload: {
            usersFromContext: response.data.users,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const getGroupsFromContext: ContextProviderType['getGroupsFromContext'] = useCallback(
    async (contexts) => {
      try {
        const response = await axios.get('/api/admin/group/list', {
          params: {
            contexts: contexts?.join(',') || '',
          },
        });
        dispatch({
          type: Types.GET_GROUPS_FROM_CONTEXT,
          payload: {
            groups: response.data.Groups,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const inviteUserToContext: ContextProviderType['inviteUserToContext'] = useCallback(
    async (contexts, email: string, read_write: boolean) => {
      try {
        await axios.post(
          '/v1/manager/contexts/invite',
          {
            email: email,
            name: email,
            read_write: read_write,
          },
          {
            params: {
              contexts: contexts.join(','),
            },
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const inviteUserToDomain: ContextProviderType['inviteUserToDomain'] = useCallback(
    async (email: string, read_write: boolean) => {
      try {
        await axios.post('/v1/manager/domains/invite?contexts=1', {
          email: email,
          root: read_write,
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const registerNewDomain: ContextProviderType['registerNewDomain'] = useCallback(
    async (
      domainName: string,
      organizationName: string,
      country: string,
      tax_id: string,
      email: string,
      street: string,
      number: string,
      complement: string,
      city: string,
      state: string,
      zipCode: string,
      contextName: string,
      contextDescription: string
    ) => {
      try {
        await axios.post(`/v1/manager/domains/register?contexts=1`, {
          name: domainName,
          organization: {
            name: organizationName,
            country: country,
            tax_id: tax_id,
            email: email,
            street: street,
            number: number,
            complement: complement,
            city: city,
            state: state,
            zip_code: zipCode,
          },
          context: {
            name: contextName,
            description: contextDescription,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const inviteUser: ContextProviderType['inviteUser'] = useCallback(
    async ({ email, group, organizationId }) => {
      const result = await POSTOrganizationInvite({
        email,
        organizationId,
        read_write: group === 'admin',
      });
      if (!result.ok) {
        return { error: result.error || new Error('Failed to invite user') };
      }
      return { ok: true };
    },
    []
  );

  const getOverview: ContextProviderType['getOverview'] = useCallback(
    async (contexts: number[]) => {
      return await GETManagerOverview(contexts);
    },
    []
  );

  const getChatList = useCallback(async () => {
    try {
      const response = await axios.get('/v1/chat/list?');
      dispatch({
        type: Types.GET_CHAT_LIST,
        payload: {
          messagesList: response.data.data.chats,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getChatHistory: ContextProviderType['getChatHistory'] = useCallback(
    async (conversation_id?: string, page_size?: number) => {
      try {
        const response = await axios.get('/v1/chat/history?', {
          params: {
            conversation_id: conversation_id,
            page_size: page_size,
          },
        });
        dispatch({
          type: Types.GET_CHAT_HISTORY,
          payload: {
            messagesHistory: response.data.data,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const getNewChat: ContextProviderType['getNewChat'] = useCallback(
    async (conversation_id: string, content: string) => {
      try {
        const payload = { conversation_id, content };
        const response = await axios.post(`/v1/chat/new?`, payload);
        dispatch({
          type: Types.GET_NEW_CHAT,
          payload: {
            newChat: response.data.data.chat,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const createMessage: ContextProviderType['createMessage'] = useCallback(
    async (context_id, conversation_id: string, content: string) => {
      try {
        const payload = { conversation_id, content };
        const response = await axios.post('/v1/chat?', payload, {
          params: { contexts: context_id },
        });
        dispatch({
          type: Types.POST_LLM_RESPONSE,
          payload: {
            createLLM: response.data.data.chat,
          },
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    []
  );

  // ----------------------------------------------------------------------

  const memorizedValue: ContextProviderType = useMemo<ContextProviderType>(
    () => ({
      ...state,
      setSelectedContexts,
      inviteUserToDomain,
      getContextsPoints,
      getContextsPackages,
      getContextsDisk,
      getContextsFromDomain,
      getUsersFromDomain,
      getDomain,
      getOrganization,
      getUserOrganization,
      getOrganizationUsers,
      createContext,
      createSubOrganizations,
      createContract,
      patchContract,
      getUsersFromContext,
      getGroupsFromContext,
      createNewUserInContext,
      inviteUserToContext,
      registerNewDomain,
      inviteUser,
      patchOrganization,
      getOverview,
      patchContexts,
      patchAllowPoints,
      getSubOrganization,
      getSubOrganizationContract,
      removeUserOrganizations,
      inviteUserOrganizations,
      updateUserToPolicy,
      getPolicies,
      getChatList,
      getChatHistory,
      getNewChat,
      createMessage,
    }),
    [
      state,
      createContext,
      createSubOrganizations,
      createContract,
      patchContract,
      getContextsPoints,
      getContextsPackages,
      getContextsDisk,
      getContextsFromDomain,
      getUsersFromDomain,
      getDomain,
      getOrganization,
      getUserOrganization,
      getOrganizationUsers,
      inviteUserToDomain,
      setSelectedContexts,
      getUsersFromContext,
      getGroupsFromContext,
      inviteUser,
      createNewUserInContext,
      registerNewDomain,
      inviteUserToContext,
      patchOrganization,
      getOverview,
      patchContexts,
      patchAllowPoints,
      getSubOrganization,
      getSubOrganizationContract,
      removeUserOrganizations,
      inviteUserOrganizations,
      updateUserToPolicy,
      getPolicies,
      getChatList,
      getChatHistory,
      getNewChat,
      createMessage,
    ]
  );

  return <ContextProvider.Provider value={memorizedValue}>{children}</ContextProvider.Provider>;
}
