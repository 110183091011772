// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const br = {
  login: {
    selectLanguage: `Selecionar idioma`,
    logoTextMyrmex: `Especialista em defesa e prevenção de ataques cibernéticos`,
    cardTitle: `Entre no Myrmex`,
    cardSubTitle: `Não tem uma conta?`,
    cardSubTitleSingUp: `Cadastre-se`,
    dialogTitle: `Você é um domínio raiz e esqueceu sua senha?`,
    dialogContentText: `Insira seu e-mail utilizado para fazer login na plataforma para que um colaborador da Ainext possa entrar em contato com você para confirmar algumas informações para redefinir sua senha`,
    inputLabelEmail: `Endereço de e-mail`,
    inputLabelPassword: `Senha`,

    errorEmailRequired: `Email é requerido`,
    errorEmailValid: `Email deve ser um endereço de email válido`,
    errorPasswordRequired: 'Senha é requerida',
    errorPasswordWeak: `Senha é muito fraca`,
    errorPasswordMatch: 'Senhas devem ser iguais',
    NeedHelp: `Precisa de ajuda?`,

    checkboxLabelRemember: `Lembrar e-mail`,
    linkForgotPassword: `Esqueceu a senha?`,
    buttonLogin: `Login`,
    continueSocial: `Ou continue com`,
    buttonGoogle: `Google`,
    buttonMicrosoft: `Microsoft`,
    buttonAWS: `Amazon`,
    started: {
      cardTitleStarted: `Comece a usar o Myrmex.`,
      cardSubTitleAlready: `Já tem uma conta?`,
      cardSubTitleSingIn: `Entrar`,
      cardInputFullName: `Nome completo`,
      cardInputCellPhone: `Celular`,
      helmetStarted: `Verificar código`,
      checkEmail: `Por favor, verifique seu e-mail!`,
      checkEmailBody: `Enviamos por e-mail um código de confirmação de 6 dígitos para {{ email }}, por favor insira o código na caixa abaixo para verificar seu e-mail.`,
      dontHaveCode: `Não tem um código?`,
      linkResendCode: `Reenviar código`,
      timeResendCode: `Aguarde o tempo acabar para reenviar um novo código!`,
      cardCheckEmail: `Verifique sua caixa de entrada de e-mail`,
      cardVerifyCode: `Enviamos um e-mail de confirmação de 6 dígitos para o seu endereço de e-mail. Por favor insira o código na caixa abaixo para verificar seu e-mail.`,
      cardVerifyCode2: `Se você não recebeu o e-mail, verifique seu spam ou volte ao inicio certificando-se que o endereço de email está correto antes de solicitar um novo código.`,
      cardInputAlert: `Código obrigatório`,
      cardSnackbarSuceess: `Verificado com sucesso!`,
      buttonVerify: `Verificar`,
      TryAgain: `Tentar novamente`,
      cardPasswordRequired: `Senha obrigatória`,
      cardEmailRequired: `E-mail é obrigatório`,
      cardFullNameRequired: `Nome completo obrigatório`,
      cardEmailAddressRequired: `O e-mail deve ser um endereço de e-mail válido`,
      cardCellPhoneRequired: `É necessário ter celular`,
    },
    password: {
      cardTitlePassword: `Recuperação de senha`,
      cardInputPassword: `Senha`,
      cardInputRepeatPassword: `Repetir senha`,
      lowercase: 'Uma letra minúscula',
      capital: 'Uma letra maiúscula',
      number: 'Um número',
      specialChar: 'Um caractere especial',
      match: 'Correspondência de senhas',
      minLength: 'Mínimo {{ minLength }} caracteres',
      cardResetPassword: `Recuperar senha`,
      cardTitleRecovePassword: `Recuperar sua senha`,
      cardSubTitleRecoverPassword: `Digite seu e-mail utilizado para login na plataforma Myrmex:`,
      cardEmailRecovery: `recuperação de e-mail`,
      cardSnackbarSuceess: `Solicitação enviada com sucesso. Entraremos em contato com você por e-mail!`,
      buttonSend: `Enviar`,
      resetWeak: `Fraco`,
      resetAlmost: `Quase lá`,
      resetStrong: `Forte`,
    },
    buttonNext: `Próximo`,
    buttonReturnSingIn: `Voltar para entrar`,
    expiredLinkTitle: `Ops, este é um link expirado`,
    expiredLinkSubTitle30: `Por motivos de segurança, os links de redefinição de senha expiram após 30 minutos.`,
    expiredLinkSubTitle24: `Por motivos de segurança, os links de redefinição de senha expiram após 24 horas.`,
    social: {
      OrContinueWithSignIn: 'Ou continue com',
      completeSingup: {
        AlmostFinished: 'Quase lá',
        body: 'Para garantir a melhor experiência, complete seus dados abaixo e acesse a plataforma:',
        unavailable: 'Usuário não encontrado',
      },
      confirmSocialAuth: {
        ConfirmLogin: 'Confirmar Login',
        WelcomeBack: 'Bem-vindo de volta!',
        body: `Já existe uma conta na plataforma vinculada a este e-mail.
              <br />
              Para sua experiência ser completa, unifique sua conta Myrmex com sua conta {{ TokenProvider }}.
              <br />
              <br />
              Para conectar suas contas, confirme com sua senha Myrmex:`,
        LoginFailure: 'Falha no login.',
        unifyButton: `Usar {{ TokenProvider }} para acessar minha conta Myrmex`,
      },
    },
  },
  home: {
    link: {
      'device.add': {
        title: `Registre seu dispositivo`,
        body: `Qualquer hardware ou software conectado a uma rede ou sistema e que possa ser usado para monitoramento`,
        button: `Novo dispositivo`,
      },
      'device.view': {
        title: `Veja seus dispositivos`,
        // body: `Monitor your organization's hardware or software equipment that is connected to the Myrmex platform.`,
        body: 'Monitore equipamentos de hardware ou software conectados à plataforma Myrmex de sua organização.',
        button: `Ver dispositivos`,
      },
      'integration.add': {
        title: `Registre sua integração`,
        // body: `Connects your applications and allows them to share data. Monitor data from your integrations quickly and securely.`,
        body: 'Conecte aplicações e colete dados. Monitore dados de suas integrações de forma rápida e segura.',
        button: `Nova integração`,
      },
      'integration.view': {
        title: `Veja sua integração`,
        // body: `Applications integrated into the platform allow data sharing. Monitor your integrations quickly and securely.`,
        body: 'Aplicações integradas à plataforma permitindo compartilhamento de dados. Monitore suas integrações de forma rápida e segura.',
        button: `Ver integrações`,
      },
      'sub-org.add': {
        title: `Registre sua sub-organização`,
        // body: `In the Myrmex system refers to the corporate or institutional structure of a client.`,
        body: 'No sistema Myrmex, refere-se à estrutura corporativa ou institucional de um cliente.',
        button: `Nova sub-organização`,
      },
      'sub-org.view': {
        title: `Veja suas sub-organizações`,
        // body: `In the Myrmex system refers to the corporate or institutional structure of a client. `,
        body: 'No sistema Myrmex, refere-se à estrutura corporativa ou institucional de um cliente.',
        button: `Ver sub-organizações`,
      },
      'context.add': {
        title: `Registre seu contexto`,
        // body: `Is a logical structure that represents the segmentation and organization of data.`,
        body: 'É uma estrutura lógica que representa a segmentação e organização de dados.',
        button: `Novo contexto`,
      },
      'context.view': {
        title: `Veja seus contextos`,
        // body: `Is a logical structure that represents the segmentation and organization of data.`,
        body: 'É uma estrutura lógica que representa a segmentação e organização de dados.',
        button: `Ver contextos`,
      },
      'team.add': {
        title: 'Convide seu time',
        // body: 'Be up to date on team activities and task assignments',
        body: 'Esteja atualizado sobre as atividades da equipe e atribuições de tarefas',
        button: 'Convidar',
      },
    },
    totals: {
      devices: `Total de dispositivos`,
      integrations: `Total de integrações`,
      alerts: `Total de alertas`,
      LastPeriod: `Ultimo {{period}}`,
    },
    invite: {
      Organization: 'Organização',
      UserGroup: 'Grupo de acesso',
      Cancel: 'Cancelar',
      Send: 'Enviar',
      Admin: 'Administrador',
      User: 'Usuário',
      OrganizationRequired: 'Organização é obrigatória',
      TeamInvited: 'Time convidado!',
      TeamInviteFailed: 'Convite não foi enviado.',
    },
    invitation: {
      title: 'Você está convidado a se juntar à organização: {{name}}',
      body_variation_1: `Ao aceitar, você se tornará um membro desta organização, ganhando visibilidade em seu ambiente.
      Por favor, esteja ciente de que ao fazer isso, você perderá o acesso à sua organização atual.`,
      body_variation_2: `{{name}} convidou você, ao aceitar você se torna membro desta organização e
            ganha visibilidade de seu ambiente. Com isso você perderá o acesso a sua organização atual.`,
      radioAccept: 'Aceito fazer parte da organização {{name}}.',
      radioDecline: 'Recusar Convite.',
      buttonDecline: 'Recusar',
      buttonJoin: 'Aceitar convite',
      InvitationAnswered: 'Convite respondido!',
      InvitationAnswerFailed: 'Falha ao responder convite.',
    },
  },
  Alerts: 'Alertas',
  Home: 'Home',
  Dashboard: 'Painel',
  NoDataAvailable:
    'Nenhum dado disponível. Por favor, Verifique se o contexto desejado esta selecionado.',
  Endpoints: 'Endpoints',
  Integrations: 'Integrações',
  loadingAlert: 'Aguarde, <br> Estamos carregando o alerta...',
  preparingEnvironment: `Preparando seu ambiente...`,
  noContextSelectedTitle: 'Nenhum resultado encontrado',
  noContextSelectedSubTitle: 'Verifique se o contexto desejado está selecionado',
  integrations: {
    new: 'Nova Integração',
    authorization: {
      authorizedSnack: 'Autorização completa!',
      unauthorizedSnack: 'Desautorizado completa!',
      authorized: 'Autorizado',
      unauthorized: 'Desautorizado',
    },
    vendorFilterPlaceholder: 'Fornecedores',
    searchFilterPlaceholder: 'Buscar...',
    clearFilters: 'Limpar filtros',
    addIntegrationsButton: 'Adicionar Integração',
    connect: 'Conectar',
    registration: {
      title: 'Registro de Integração',
      prerequisites: 'Pré-requisitos',
      instructions_one: 'Preencha os detalhes de dispositivo abaixo para iniciar a integração:',
      contexts_description: 'Esta integração vai ser associada com o contexto selecionado.',
      insert_placeholder: 'Preencha aqui',
      integration_name: 'Nome',
      integration_description: 'Descrição',
      integration_user: 'Usuário',
      integration_SNMP_description: 'Myrmex usa SNMP versão 3 por padrão e a porta padrão é 161.',
      integration_SNMP_password: 'Senha SNMP',
    },
  },
  Logs: 'Logs',
  Management: 'Gerenciamento',
  Contexts: 'Contextos',
  header: {
    'context.SelectContext': 'Selecionar Contexto',
  },
  AlertsPage: {
    title: 'Alertas',
    details: {
      title: 'Detalhes do Alerta',
      assignToMe: 'Atribuir Se',
      resolveAlert: 'Resolver Alerta',
      aiRecommendations: 'Recomendações da IA',
      showMore: 'Mostrar Mais',
      showLess: 'Mostrar Menos',
      riskScore: 'Pontuação de Risco',
      threatType: 'Tipo de Ameaça',
      events: 'eventos',
      threatIntelligenceDetect: 'indicadores de threat intelligence detectados',
      actor: 'Ator',
      handler: 'Controlador',
      target: 'Alvo',
      ip_address: 'Endereço de IP',
      hostname: 'Hostname',
      user: 'Usuário',
      protocol: 'Protocolo',
      duration: 'Duração',
      name: 'Nome',
      role: 'Função',
      response: 'Resposta',
      parameters: 'Parâmetros',
      category: 'Categoria',
      method: 'Método',
      object: 'Objeto',
      result: 'Resultado',
      identifier: 'Identificador',
      nat_ip_address: 'Endereço IP NAT',
      country: 'País',
      state: 'Estado',
      city: 'Cidade',
      operation_system: 'Sistema Operacional',
      severity: 'Severidade',
      attack: 'Ataque',
      profile: 'Perfil',
      group: 'Grupo',
      version: 'Versão',
      email: 'Email',
      message: 'Mensagem',
      file: 'Arquivo',
      domain: 'Domínio',
      process: 'Processo',
      service: 'Serviço',
      file_path: 'Caminho do Arquivo',
      port: 'Porta',
      mac_address: 'Endereço MAC',
      net_interface: 'Interface de Rede',
      net_session: 'Sessão de Rede',
      policy: 'Política',
      application: 'Aplicação',
      url: 'URL',
      name_server: 'Servidor DNS',
      library: 'Biblioteca',
      registry_key: 'Chave de Registro',
      hash: 'Hash',
      command: 'Comando',
      parameter: 'Parâmetro',
      data: 'Dados',
      packets: 'Pacotes',
      bytes: 'Bytes',
      resource: 'Recurso',
      agent_type: 'Tipo de Agente',
      agent_subtype: 'Subtipo de Agente',
      coordinates: 'Coordenadas',
      agent_name: 'Nome do Agente',
      agent_cpu: 'CPU do Agente',
      agent_memory: 'Memória do Agente',
      agent_id: 'ID do Agente',
      agent_gateway: 'Gateway do Agente',
      authentication_policy: 'Política de Autenticação',
      storage: 'Armazenamento',
      agent_uptime: 'Tempo de Atividade do Agente',
      count: 'Contagem',
    },
  },
  'Email is required': 'Email é obrigatório',
  'Email must be a valid email address': 'Deve ser um endereço de e-mail válido',
  'Organization is required': 'Organização é obrigatória',
  'Group is required': 'Grupo é obrigatório',
  period: 'período',
  year: 'ano',
  year_other: 'anos',
  month: 'mês',
  month_other: 'meses',
  week: 'semana',
  week_other: 'semanas',
  day: 'dia',
  day_other: 'dias',
  hour: 'hora',
  hour_other: 'horas',
  'user.profile': {
    link: 'Meu perfil',
    title: 'Conta de Usuário',
    OldPassword: 'Senha antiga',
    NewPassword: 'Senha nova',
    ConfirmNewPassword: 'Confirme a nova senha',
    allowedFiles: 'Permitido {{files}}',
    maxSize: 'Tamanho máximo de {{maxSize}}',
    userProfile: 'Perfil',
    ChangePassword: 'Alterar senha',
    Authentication: 'Autenticação',
    Back: 'Voltar',
    SaveChanges: 'Salvar alterações',
    Organization: 'Organização',
    Name: 'Nome',
    Phone: 'Telefone',
    Email: 'Email',
    updatePhoto: 'Atualizar foto',
    uploadPhotoError: 'Erro ao enviar foto',
    userSaveError: 'Erro ao salvar perfil de usuário',
    userSaveSuccess: 'Perfil de usuário atualizado',
    Acknowledged: 'Reconhecido',
    'Closed Alerts': 'Alertas fechados',
    logoutError: 'Não foi possível sair!',
    linkSuccess: 'Conta vinculada com sucesso!',
    SocialLogin: 'Login Social',
    socialLoginInstructions:
      'Facilite seu acesso: Vincule sua conta Myrmex usando uma das opções abaixo. <br /> Certifique-se de que o e-mail cadastrado na plataforma seja o mesmo do login social',
    confirmPassword: 'Para conectar sua conta à plataforma, confirme com sua senha Myrmex:',
    useProvider: 'Use {{ useProvider }} para acessar conta Myrmex',
    remove: 'Remover',
    disconnect:
      'Para desvincular sua conta da plataforma, clique em remover, você será encaminhado para redefinir senha.',
  },
  management: {
    breadcrumbs: 'Gerenciamento',
    alert: {
      success: 'Alteração salva com sucesso',
      error: 'Erro ao salvar alteração',
      contractSuccess: 'Contrato cancelado com sucesso',
      contractCancel: 'Erro ao cancelar contrato',
      errordays: 'O contrato deve ter pelo menos 30 dias',
    },
    invalidDate: 'Data Inválida',
    insufficientPointsTitle: 'Pontos Insuficientes',
    insufficientPointsMessage:
      'Você não tem pontos suficientes para adquirir este pacote. Verifique seu saldo de pontos disponíveis ou adquira mais pontos para prosseguir com a compra.',
    confirmCancelTitle: 'Confirmar Cancelamento do Contrato',
    confirmCancelMessage:
      'Você deseja confirmar o cancelamento deste contrato? Ele permanecerá ativo até {{endDate}}. Após essa data, o contrato expirará e todos os dispositivos autorizados serão automaticamente desautorizados.',
    transactionNotCompletedTitle: 'Transação Não Concluída',
    transactionNotCompletedMessage:
      'A transação não foi bem-sucedida. Por favor, entre em contato com o suporte.',
    contractDetails: 'Detalhes do Contrato',
    authorizationTitle: 'Autorização para Criação de Contrato Mensal',
    period: 'Período: {{startDate}} a {{endDate}}',
    devices: 'Dispositivos: ',
    integration: 'Integração: ',
    xdr: 'XDR: ',
    mdr: 'MDR: ',
    storage: 'Armazenamento: ',
    transactionPoints: 'Esta transação usará {{points}} pontos. Você autoriza?',
    input: {
      country: 'País',
      cnpj: 'CNPJ',
      companyTradeName: 'Nome Comercial da Empresa',
      companyName: 'Nome da Empresa',
      startDate: 'Data de Início',
      endDate: 'Data de Expiração',
      device: 'Dispositivo',
      integration: 'Integração',
      xdr: 'XDR',
      mdr: 'MDR',
      storage: 'Armazenamento',
      points: 'Pontos',
      actions: 'Ações',
    },
    button: {
      save: 'Salvar',
      cancel: 'Cancelar',
      yes: 'Sim',
      create: 'Criar',
      close: 'Fechar',
      ok: 'Ok',
      send: 'Enviar',
      confirm: 'Confirmar',
      newContract: 'Novo contrato',
      confirmCancel: 'Confirmar Cancelamento do Contrato',
      cancelContract: 'Cancelar Contrato',
    },
    dialog: {
      transactionNotCompleted: 'Transação Não Concluída',
      transactionError:
        'A transação não foi bem-sucedida. Por favor, entre em contato com o suporte.',
    },
    popover: {
      insufficientPointsTitle: 'Pontos Insuficientes',
      insufficientPointsMessage:
        'Você não tem pontos suficientes para adquirir este pacote. Verifique seu saldo de pontos disponíveis ou adquira mais pontos para prosseguir com a compra.',
    },
    checkbox: {
      allowSubOrganization: 'Permitir que a sub-organização consuma os pontos desta organização.',
    },
  },
  popUpAllowSubOrganization: {
    title: 'Permitir que a sub-organização consuma {{organizationName}} pontos',
    description:
      'Por exemplo, se alguma das sub-organizações gerenciadas por {{organizationName}} ficar sem pontos. Os pontos de {{organizationName}} serão utilizados. Confirmar esta ação?',
  },
  points: {
    title: 'Pontos',
    subTitleAvailable: 'Disponíveis',
    subTitleConsumed: 'Consumidos',
    descriptionDay: 'Dia',
    descriptionWeek: 'Semana',
    descriptionMonth: 'Mês',
  },
  package: {
    title: 'Pacotes',
    subTitleDevice: 'Dispositivo',
    subTitleIntegration: 'Integração',
    subTitleMDR: 'MDR',
    subTitleXDR: 'XDR',
  },
  diskAvailable: {
    title: 'Disco Disponível',
    description: 'usado de ',
    subtitle: 'Usando',
  },
  tabContexts: 'Contextos',
  tabSubOrganizations: 'Sub-organizações',
  contexts: {
    placeholder: 'Pesquisar',
    button: 'Novo Contexto',
    name: 'Nome',
    organization: 'Organização',
    totalDevice: 'Total de Dispositivos',
    totalIntegration: 'Total de Integrações',
    actions: 'Ações',
  },
  subOrganization: {
    placeholder: 'Pesquisar',
    button: 'Nova Sub-organização',
    name: 'Nome',
    contracts: 'Contratos',
    points: 'Pontos',
    contexts: 'Contextos',
    device: 'Dispositivo',
    integration: 'Integração',
    mdr: 'MDR',
    storage: 'Armazenamento',
    modal: {
      title: 'Nova Sub-organização',
      description:
        'No sistema Myrmex, refere-se à estrutura corporativa ou institucional de um cliente gerenciada pela ',
      placeholderCountry: 'País',
      placeholderCnpj: 'CNPJ',
      placeholderCompanyName: 'Razão Social',
      placeholderTradeName: 'Nome Fantasia',
    },
  },
  users: {
    title: 'Usuários',
    columnsUser: 'Usuário',
    columnsName: 'Nome Completo',
    columnsGroups: 'Grupos',
    columnsVerified: 'Verificado',
    columnsAction: 'Remover Usuário',
    button: 'Convidar Usuário',
    invite: {
      title: 'Convidar Usuário',
      email: 'Endereço de Email',
      emailRequired: 'Email é obrigatório',
      emailError: 'Endereço de email inválido',
      group: 'Grupo',
      groupRequired: 'Seleção de grupo é obrigatória',
      group1: 'Grupo Acesso Total à Organização',
      group2: 'Grupo Somente Leitura à Organização',
      success: 'Convite enviado com sucesso',
      error: 'Erro ao enviar convite',
      invitation: 'Convite enviado',
      employee:
        'O colaborador receberá um convite para a organização {{organizationName}} no seu email.',
      employeeAccess:
        'Para acessar o Myrmex, o colaborador precisa validar o token de confirmação de email.',
      alertUpdatePolicy: 'Política atualizada com sucesso',
      alertWarningPolicy: 'Nenhuma política correspondente encontrada',
      alertErrorPolicy: 'Falha ao atualizar política',
      fullAccess: 'Acesso Total',
      readOnly: 'Somente Leitura',
    },
    remove: {
      title: 'Remover Usuário',
      subtitle: 'Tem certeza de que deseja remover este usuário da organização ',
      description: ' perderá todo o acesso à sub-organização e contextos.',
    },
  },

  endpoints: {
    Authorization: 'Autorização',
    AllEndpoints: 'Todos Endpoints',
    Authorized: 'Autorizados',
    Unauthorized: 'Não autorizados',
    MonitoringAction: {
      Monitoring: 'Monitoramento',
      Description: 'Gostaria de monitorar o status deste endpoint?',
      NumberOfTests: 'Número de testes',
      TestsRange: 'Entre 1 e 10',
      Cancel: 'Cancelar',
      Save: 'Salvar',
      Start: 'Iniciar',
      Restart: 'Reiniciar',
      Stop: 'Parar',
    },
    deviceIsAServer: 'Esse dispositivo é um servidor',
  },
  alert: {
    status: {
      Status: 'Status',
      open: 'Aberto',
      acknowledged: 'Reconhecido',
      closed: 'Fechado',
    },
    crititicality: {
      Critical: 'Critico',
      High: 'Alto',
      Medium: 'Médio',
      Low: 'Baixo',
      Information: 'Informação',
    },
  },
  logs: {
    logsDate: 'Data',
    logsActions: 'Ações',
    copiedText: 'Texto copiado!',
    downloadCompleted: 'Download concluído!',
    logsVolumetry: 'Volumetria de Logs',
    logsTheLast24: 'Nas últimas 24 horas',
    search: 'Pesquisar',
    refresh: 'Atualizar',
    downloadCSV: 'Baixar CSV',
    examplePlaceholder:
      'Exemplo... collector_os:windows&event_eventdata_destport:10050&Vendor:Fortinet',
    today: 'Hoje',
    last15Minutes: 'Últimos 15 minutos',
    last30Minutes: 'Últimos 30 minutos',
    last1Hour: 'Última 1 hora',
    last24Hours: 'Últimas 24 horas',
    last7Days: 'Últimos 7 dias',
    last30Days: 'Últimos 30 dias',
    last90Days: 'Últimos 90 dias',
    last1Year: 'Último 1 ano',
    expandedDocument: 'Documento expandido',
    goTo: 'Ir para',
    table: 'Tabela',
    json: 'JSON',
    field: 'Campo',
    value: 'Valor',
    action: 'Ação',
    addFieldToFilter: 'Adicionar campo ao filtro na pesquisa',
    copyCode: 'Copiar código',
    noDate: 'Sem data cadastrada',
    availableFields: 'Campos disponíveis',
    status: 'Status',
    lookBackTime: 'Tempo-anterior',
  },
  months: {
    jan: 'Jan',
    feb: 'Fev',
    mar: 'Mar',
    apr: 'Abr',
    may: 'Mai',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Ago',
    sep: 'Set',
    oct: 'Out',
    nov: 'Nov',
    dec: 'Dez',
  },
  chat: {
    title: 'Cybersecurity Assistant',
    newChat: 'Nova Conversa',
    copy: 'Copiar',
    inputPlaceholder: 'Como a MYRMEX pode ajudar você hoje?',
    sendButton: 'Enviar',
    charLimitError: 'Não é permitido digitar mais de 500 caracteres.',
    errorMessage:
      'Ocorreu um problema. Por favor, aguarde um instante e tente enviar sua mensagem novamente.',
    loadingMessage: 'Digitando',
    helloMessage: 'Olá, {{userName}}!',
    helpMessage: 'Como posso te ajudar?',
    loadMoreMessages: 'Carregar mais mensagens',
    shiftMessage: 'Use shift + return para nova linha',
    noContextSelectedError: 'Selecione um contexto para iniciar a conversa.',
    downloadInstallApp: 'Como baixar e instalar o aplicativo',
    appWorksOnComputer: 'Como o aplicativo funciona no computador',
    appProtectMe: 'Como o aplicativo pode me proteger',
    FrequentlyAsked: 'Perguntas frequentes',
  },
  chatHistory: {
    title: 'Histórico de Conversas',
    searchPlaceholder: 'Pesquisar',
    today: 'Hoje',
    yesterday: 'Ontem',
    last7Days: 'Últimos 7 dias',
    last30Days: 'Últimos 30 dias',
  },
};

export default br;
